const COOKIE_MAX_AGE = 60 * 60 * 24 * 400;

function checkAndSetFuidCookie() {
  const fuid = getCookie('fuid');
  if (!fuid) {
    const generatedFuid = uuid();
    document.cookie = `fuid=${generatedFuid}; path=/; expires=${new Date(Date.now() + COOKIE_MAX_AGE * 1000).toUTCString()}`;
  }
}

function getCookie(name) {
  const cookieName = `${name}=`;
  const decodedCookie = decodeURIComponent(document.cookie);
  const cookieArray = decodedCookie.split(';');
  for (let i = 0; i < cookieArray.length; i++) {
    let cookie = cookieArray[i];
    while (cookie.charAt(0) === ' ') {
      cookie = cookie.substring(1);
    }
    if (cookie.indexOf(cookieName) === 0) {
      return cookie.substring(cookieName.length, cookie.length);
    }
  }
  return '';
}

function uuid(a, b) {
  for (b = a = ''; a++ < 36; b += a * 51 & 52 ?
    (a ^ 15 ?
      8 ^ Math.random() * (a ^ 20 ? 16 : 4) :
      4
    ).toString(16)
          : '-'
  ) {}
  return b
}

// Call the function to check and set the "fuid" cookie
checkAndSetFuidCookie();
